/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'
import './styles/atendimento.scss'
import { Helmet } from 'react-helmet'

const FaleConosco = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/fale-conosco/', name: 'Fale Conosco' }
    ]
  }

  useEffect(() => {
      if (!window.google) {
        const script = document.createElement('script')
        script.src = 'https://raresponde.reclameaqui.com.br/js/faleconosco.js'
        const insercao = document.getElementsByTagName('script')[0]
        insercao.parentNode.insertBefore(script, insercao)
        script.addEventListener('load', e => {
          console.log('Now available!')
        })
      }
  }, [])

  return (
    <Layout breadCrumb={breadC}>
      <Pagina pagina={data.pagina}/>
      <div className="bg-gray2 mt-5">
        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12">
              <h2 className="text-black">Para mais dúvidas, perguntas, sugestões e outros assuntos,<br/> preencha os campos abaixo:</h2>
            </div>
          </div>
          <div id="faleconosco_raresponde" width="720" height="800" addressurl="raresponde.reclameaqui.com.br/faleconosco/index" client="78721"></div>
        </div>
      </div>
    </Layout>
  );
}

export default FaleConosco

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/fale-conosco/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
